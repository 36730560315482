.monster-page {
    background: url('https://pumpkinstudio.com.br/wp-content/uploads/2021/09/FLORESTA-@-scaled.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    width: 100%;
    max-width: 100%;
    padding-bottom: 50px;
}

.monster-principal-info .monster-name {
    font-size: 2.5em;
}

.monster-principal-info .monster-tendencia {
    font-size: 1.2em;
}

.engloba-tudo {
    color: var(--font-color-white);
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
}

.monster-ficha {
    background-color: rgba(11, 14, 19, 0.719);
}

.monster-ficha .monster-principal-info .monster-name {
    font-size: 1.5em;
}

.monster-detail {
    max-width: 100%;
}

.monster-page::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(26, 25, 27, 0.932);
    opacity: 1;
    z-index: 0;
}

.monster-principal-info {
    position: relative;
    z-index: 1;
    margin-bottom: 15px;
    font-size: 18px;
}

.monster-name {
    padding-top: 25px;
    white-space: nowrap;
    text-align: center;
    font-family: Old Fenris;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    color: rgba(255, 161, 54, 1);
    text-transform: uppercase;
    text-shadow: 0.1em 0.1em 0.2em black;
    z-index: 2 !important;
}

.monster-tendencia {
    white-space: nowrap;
    text-align: center;
    font-family: Libertinus Serif;
    font-style: italic;
    font-weight: normal;
    font-size: 18px;
    color: rgba(209, 205, 192, 1);

}

.monster-status {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: stretch;
    justify-content: center;
    font-family: Libertinus Serif;
    margin-bottom: 7%;
}

.monster-status-infos {
    margin-left: 5px;
    margin-right: 5px;

}

.monster-status-infos span {
    display: flex;
    width: 100%;
    font-size: 28px;
    flex-direction: row;
    align-content: stretch;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.monster-status-infos-first {
    height: 70px;
    width: 70px;
    border: 2.5px solid #988e6b;
    position: relative;
    background-color: #000;
}

.monster-status-infos-first span {
    display: flex;
    width: 100%;
    height: 100%;
    font-size: 28px;
    flex-direction: row;
    align-content: stretch;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.monster-status-infos-second {
    height: 40px;
    width: 40px;
    border: 1px solid #988e6b;
    position: absolute;
    top: 90%;
    left: 20%;
    transform: rotate(-45deg);
    background-color: rgb(0, 0, 0);
    z-index: 2;
}

.monster-status-infos-second span {
    transform: translate(0px, 0px) rotate(+45deg);
    transform-origin: center;
    display: flex;
    width: 100%;
    height: 100%;
    font-size: 28px;
    flex-direction: row;
    align-content: stretch;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.monster-status-infos-third {
    height: 40px;
    width: 40px;
    border: 1px solid #988e6b;
    position: absolute;
    top: 100%;
    left: 20%;
    transform: rotate(-45deg);
    background-color: #988e6b;
    z-index: 1;
}

.monster-row {
    color: #fff;
    font-size: 18px;
    margin-right: 15px;
    margin-left: 15px;
}

.monster-image {
    max-width: 100vw;
}

.monster-image img {
    display: flex;
    max-width: 450px;
    max-height: 450px;
    transform: scale(1.5)
}



.secundary-monster-name {
    max-width: 100%;
    text-align: center;
    font-family: Old Fenris;
    font-style: normal;
    font-weight: normal;
    margin-left: 15px;
    font-size: 23px !important;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    text-shadow: 0.1em 0.1em 0.2em black;
}

.monster-text {
    margin-top: 15px;
    font-family: Old Fenris;
    font-size: 1.22em;
    position: relative;
    margin-bottom: 25%;
    text-shadow: 0.1em 0.1em 0.2em black;
}

.monster-ficha {
    /* Layout Properties */

    width: 100%;
    height: 100%;
    /* UI Properties */
    border: 1px solid #D1CDC059;
    opacity: 1;
    font-size: 15px;
    padding: 15px;

}

.ficha-actions {
    display: block;
    margin-top: 20px;
    font-weight: 600;
}

/*LEGADO*/

.ficha-hr {
    padding-top: 15px;
    padding-bottom: 15px;

}

.ficha-hr::before {
    content: "";
    position: absolute;
    display: block;
    width: calc(100% - 28px);
    height: 2px;
    background: #D1CDC059;

}

@media screen and (max-width: 320px) {
    .monster-status-infos-first {
        height: 35px !important;
        width: 33px !important;
    }

    .monster-status-infos-second {
        height: 17px !important;
        width: 17px !important;
    }

    .monster-status-infos-third{
        height: 17px !important;
        width: 17px !important;
    }

}

@media screen and (max-width: 768px) {

    .engloba-tudo {}

    .monster-page {
        overflow-x: hidden !important;
    }

    .monster-name {
        white-space: unset;
        line-height: 1;
        padding-bottom: 10px;
    }

    .monster-ficha {
        margin-bottom: 10%;
    }

    .monster-principal-info .monster-name {
        font-size: 1.8em;
    }

    .monster-ficha .monster-principal-info .monster-name {
        font-size: 0.8em;

    }

    .monster-principal-info .monster-tendencia {
        font-size: 0.8em;
    }

    .monster-status {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        align-items: stretch;
        justify-content: center;
        font-family: Libertinus Serif;
    }

    .monster-status-infos {
        margin-left: 5px;
        margin-right: 5px;

    }

    .monster-status-infos span {
        display: flex;
        width: 100%;
        font-size: 1em;
        flex-direction: row;
        align-content: stretch;
        justify-content: center;
        align-items: center;
        color: #fff;
    }

    .monster-status-infos-first {
        height: 40px;
        width: 40px;
        border: 2.5px solid #988e6b;
        position: relative;
        background-color: #000;
    }

    .monster-status-infos-first span {
        display: flex;
        width: 100%;
        height: 100%;
        font-size: 1.2em;
        flex-direction: row;
        align-content: stretch;
        justify-content: center;
        align-items: center;
        color: #fff;
    }

    .monster-status-infos-second {
        height: 20px;
        width: 20px;
        border: 1px solid #988e6b;
        position: absolute;
        top: 90%;
        left: 20%;
        transform: rotate(-45deg);
        background-color: rgb(0, 0, 0);
        z-index: 2;
    }

    .monster-status-infos-second span {
        transform: translate(0px, 0px) rotate(+45deg);
        transform-origin: center;
        display: flex;
        width: 100%;
        height: 100%;
        font-size: 0.8em;
        flex-direction: row;
        align-content: stretch;
        justify-content: center;
        align-items: center;
        color: #fff;
    }

    .monster-status-infos-third {
        height: 20px;
        width: 20px;
        border: 1px solid #988e6b;
        position: absolute;
        top: 100%;
        left: 20%;
        transform: rotate(-45deg);
        background-color: #988e6b;
        z-index: 1;
    }

    /* */

    .monster-text {
        font-size: 1.1em;
    }


    .monster-row {
        display: flex;
        flex-direction: column-reverse;
    }

    .monster-image img {
        position: relative;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10%;
        width: 100%;
        height: 100%;
        max-width: 350px;
        flex-direction: row;
        align-content: stretch;
        justify-content: center;
        align-items: center;
        transform: scale(1.5)
    }
}

@media screen and (min-width: 2000px) {
   .engloba-tudo .col-sm-4 {
    width: 36% !important;
    max-width: 40% !important;
    margin-right: 5%;
   }

   .engloba-tudo .col-sm .monster-image img {   
    margin-top: 10%;
    transform: scale(2);
}

   .engloba-tudo .col-sm .monster-ficha {    
    margin-left: 10%;
   }

}