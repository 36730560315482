.title-relacionados {
    color: var(--font-color-white)
}

.title-relacionados h6 {
    font-size: 1em;
    padding-right: 20px;
}

.relacionados {
    background-color: #0c101b;
}

.card-header-title {
    color: aliceblue;
    font-size: 20px;
}

.clicable-video {
    cursor: pointer;
    transition: all 0.5s ease;
}

.clicable-video:hover {
    transform: scale(1.01);

}

.thumbImg:hover {

    background-size: 110% !important;
}

@media (max-width: 740px) {
    .video-responsive h1 {
        font-size: 1em;
    }

    .title-relacionados h6 {
        font-size: 0.8em;
        padding-right: 15px;
    }
}