/* Image styles */
.promo-img {
    max-width: 90%;
    border: none;
    margin: 5%;
}

.mobile-image {
    max-width: 75%;
    border: none;
    margin: 5%;
}

.top-promo {
    position: relative;
    width: 100%;
    padding: 7px 0 10px;
    z-index: 3;
}

.message {
    position: relative;
    font-size: 13px;
    z-index: 2;
}

.promo-content h3 strong {
    font-size: 1.45em;
    font-family: "Titillium Web", Sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-style: normal;
    line-height: 1.2em;
    letter-spacing: 0px;
    color: #ff4900
}

#countdown {
    font-size: 2em;
    font-family: "Titillium Web", Sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-style: normal;
    line-height: 1.2em;
    letter-spacing: 0px;
    color: #ff4900
}

.top-promo-text {
    color: #3a3a3a;
    margin-bottom: 2%;
}

.top-promo-closed-text {
    color: #3a3a3a;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    font-weight: 700;
    margin-bottom: 0 !important;
}

.top-promo-closed-text-countdown {
    color: #ff4900
}

.top-promo .open-handle {
    position: absolute;
    bottom: -15px;
    left: 50%;
    margin-left: -16.5px;
    width: 33px;
    height: 35px;
    border-radius: 50%;
    z-index: 1;
}

.top-promo .open-handle:after {
    content: " ";
    position: absolute;
    top: 22px;
    left: 50%;
    margin-left: -7px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 5px solid rgb(133 50 9 / 88%)
}

.top-promo .open-handle:hover:after {
    border-top: 5px solid rgba(255, 255, 255, 0.7);
}

.top-promo.open .open-handle:after {
    border-top: 5px solid rgb(133 50 9 / 88%);
}

.top-promo.open .open-handle:hover:after {
    border-top: 5px solid rgba(255, 255, 255, 0.7);
}


.top-promo .down-arrow {
    position: absolute;
    bottom: -15px;
    left: 50%;
    margin-left: -16.5px;
    width: 33px;
    height: 35px;
    border-radius: 50%;
    z-index: 1;
}

.top-promo .down-arrow:after {
    content: " ";
    position: absolute;
    top: 22px;
    left: 50%;
    margin-left: -7px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 5px solid rgb(133 50 9 / 88%);
}

.top-promo .down-arrow:hover:after {
    border-bottom: 5px solid rgba(255, 255, 255, 0.7);
}

.top-promo.open .down-arrow:after {
    border-bottom: 5px solid rgb(133 50 9 / 88%);
}

.top-promo.open .down-arrow:hover:after {
    border-bottom: 5px solid rgba(255, 255, 255, 0.7);
}


.promo-button {
    margin-bottom: 5%;
}