#races-hero {
    height: 50%;
    overflow: hidden;
}

#races-content {
    background-color: #130d0a;
}

.mote {
    font-style: italic;
}

.first-section {
    padding: 50px 0 20px 0;
}

.text-header {
    font-size: 50px;
    text-align: center;
}

.race-title {
    font-size: 2em;
    line-height: 120%;
    margin-bottom: 10%;
}

.p-races {
    color: #ede0d5;
    font-size: 18px;
    line-height: 150%;
}