

.videos--home {
    display: inline-block;
    margin-right: 15px;
    position: relative;
    cursor: pointer;
    user-select: none;
    transition: all ease 0.5s !important;
}

.videos--home:hover {
    margin-top: -5px;
}

.videos--home img {
    width: 460px;
    height: auto;
}

.videos--home iframe {
    width: 460px;
    height: 262px;
}

.videos--home::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, #0a0a0a 5%, transparent 100%);
}

.videos--home--out {

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: stretch;
    transition: all ease 0.5s !important;
}

.videos--home--info {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 25px;
    left: 25px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    z-index: 2;
}

@media (max-width: 740px) {

    .videos--home img {
        width: 280px;
        height: auto;
    }

    .videos--home iframe {
        width: 280px;
        height: 157.5px;
    }

    .videos--home--info {
        font-size: 0.8em;
    }
}

@media (min-width: 992px) {
    .videos--home--info {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 100%;
    }
}

.play--info {
    font-size: 30px;
}

.video--desc {
    color: #c5c5c5ad;
    font-style: italic;
}