.landing-page {
    overflow: hidden;
}

.landing-page-hero {

    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    background-image: url("https://pumpkinstudio.com.br/wp-content/uploads/2020/12/12_-_Dezembro_-_2020_-_0011_-_Landing_Page_Dezembro1024_x_728.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;

}

.landing-page-hero-vertical {
    width: inherit;
    height: inherit;
    background: linear-gradient(to top, var(--bg-color-black) 40%, transparent 100%);
    background-color: transparent;
    background-image: linear-gradient(180deg, #171618b0 27%, #171618 76%);
    opacity: 1;

}

.landing-page-hero-section-one {
    padding-top: 2.5%;
    padding-bottom: 5%;
    display: flex;
    flex-wrap: wrap;

}

.landing-page-hero-section-one img {
    max-width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.landing-page-hero-section-two {
    padding-left: 30px;
    display: flex;
    color: #c0bdc2;
    font-weight: 300;
}

.landing-page-hero-section-two h1 {
    color: var(--font-color-white);
    font-size: 3em;
    font-weight: 600;
    margin-bottom: 10px;
}

.landing-page-hero-section-two span {
    font-size: 1.5em;
}

.guild-advantage {
    padding: 30px 0px 30px 15px;
    overflow-x: scroll;
}

.guild-advantage::-webkit-scrollbar {
    height: 0px;
}

.promo-card {
    background-color: var(--bg-color-black);
    color: var(--font-color-white);
}

.promo-card img {
    padding: 15px;
}

.promo-card h5 {
    font-size: 1.5em;
    font-weight: 700;
    text-align: center;
}

.landing-page-next {
    background-color: #f3f3f3;
}

@media screen and (max-width: 760px) {

    .landing-page-hero-section-one {
        flex-direction: column-reverse;
    }

    .landing-page-hero-section-one img {
        max-width: 100%;
    }

    .landing-page-hero-section-two {
        align-content: center;
        align-items: center;
    }

    .landing-page-hero-section-two span {
        font-size: 1em;
    }

    .landing-page-hero-section-two h1 {
        font-size: 1.5em;
    }

    .col-promo-card {
        width: 80%;
    }

}