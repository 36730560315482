.social-buttons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 999;
}

.discord-button {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
  border: none;
  border-radius: 50%; /* Tornar os botões redondos */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7289da; /* Cor do Discord */
}

.whatsapp-button {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
  border: none;
  border-radius: 50%; /* Tornar os botões redondos */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7289da; /* Cor do Discord */
}

.whatsapp-button {
  background-color: #25d366; /* Cor do WhatsApp */
}

.whatsapp-button img {
  width: 26px;
  height: 26px;
}

.discord-button img {
  width: 40px;
  height: 40px;
}

@media (max-width: 768px) {
  .discord-button {
    width: 50px;
    height: 50px;
  }

  .whatsapp-button {
    width: 35px;
    height: 35px;
  }

  .discord-button img {
    width: 30px;
    height: 30px;
  }

  .whatsapp-button img {
    width: 20px;
    height: 20px;
  }
}
