@import url('https://fonts.googleapis.com/css2?family=Cinzel&display=swap');

:root {
    --card-race-height: 300px;
    --card-race-width: calc(var(--card-race-height) / 1.5);
}

.card-race-body {
    display: flex;
    align-items: center;
    height: 400px;
}

.card-race {
    width: var(--card-race-width);
    height: var(--card-race-height);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0 36px;
    perspective: 2500px;
    margin: 0 8px;
}

.cover-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.card-race-wrapper {
    transition: all 0.5s;
    position: absolute;
    width: 100%;
    z-index: -1;
    /* min-height: 100%; */
}

.card-race:hover .card-race-wrapper {
    transform: perspective(900px) translateY(-5%) rotateX(25deg) translateZ(0);
    box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
}

.card-race-wrapper::before,
.card-race-wrapper::after {
    content: "";
    opacity: 0;
    width: 100%;
    height: 80px;
    transition: all 0.5s;
    position: absolute;
    left: 0;
}

.card-race-wrapper::before {
    top: 0;
    height: 100%;
    background-image: linear-gradient(to top,
            transparent 46%,
            rgba(12, 13, 19, 0.5) 68%,
            rgba(12, 13, 19) 97%);
}

.card-race-wrapper::after {
    bottom: 0;
    opacity: 1;
    background-image: linear-gradient(to bottom,
            transparent 46%,
            rgba(12, 13, 19, 0.5) 68%,
            rgba(12, 13, 19) 97%);
}

.card-race:hover .card-race-wrapper::before,
.card-race-wrapper::after {
    opacity: 1;
}

.card-race:hover .card-race-wrapper::after {
    height: 120px;
}

.race-link {
    text-decoration: none;
}

.race-link:hover {
    text-decoration: none;
}

.title {
    width: 100%;
    transition: transform 0.5s;
    color: white;
    font-family: 'Cinzel';
    font-size: 1.5rem;
    text-align: center;
}

.card-race:hover .title {
    transform: translate3d(0%, -50px, 100px);
}

.character {
    width: 100%;
    opacity: 0;
    transition: all 0.5s;
    position: absolute;
    z-index: -1;
}

.card-race:hover .character {
    opacity: 1;
    transform: translate3d(0%, -30%, 100px);
}

.character-unhover {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 99;
    transition: all 0.5s;
}

.card-race:hover .character-unhover {
    opacity: 0.1;
}

.new {
    position: absolute;
    top: 12px;
    left: 3px;
    z-index: 2;
    padding: 5px;
    background-color: red;
    color: white;
    font-size: 0.8rem;
    text-transform: uppercase;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);

    &:after {
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        border-style: solid;
    }

    &:after {
        border-width: 0 20px 20px 0;
        border-color: transparent red transparent transparent;
        bottom: -20px;
        right: 0;
    }
}