#email-auth-title {
    font-size: 38px;
    color: #fff;
    display: flex;
    justify-content: center;
    text-align: center;
    line-height: 1;
    padding-bottom: 15px;
}

#email-auth-span {
    padding: 5px 20px;
    font-size: 14px;
    color: #fff;
    display: flex;
    justify-content: center;
    text-align: center;
}

#input-confirm-email{
    text-align: center;
}

