/* GERAIS */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

:root {
    --bg-color-orange: #f28a4b;
    --bg-color-orange-strong: #ee6612;
    --bg-color-gray-strong: #bdb6b2;
    --bg-color-black: #171618;
    --bg-color-black-secundary-item: #131722;
    --bg-color-btn-green: #17cc06;
    --font-color-white: #fff;
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    font-family: "JosefinSans", sans-serif;
    background: var(--bg-color-black);
    user-select: none;

}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;   
}

.color-black {
    color: var(--bg-color-black) !important;
}

/* Track */
::-webkit-scrollbar-track {
    background: #353535a8;

}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(54, 54, 54);

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(112, 112, 112);
}

.background-black {
    min-height: 100%;    
    background-color: var(--bg-color-black);
}

.background-orange {
    min-height: 100%;    
    background-color: var(--bg-color-orange-strong);
}

.max-width-100 {
    max-width: 100%;
}

.header-padding {
    padding-top: 80px;
}

.text-center {
    text-align: center;
}

.all-ease {
    transition: all ease 0.2s;
}

.smooth {
    scroll-behavior: smooth;
}

.lateral-padding {
    padding-left: 25px;
    padding-right: 20px;
}

.lateral-padding-left {
    padding-left: 25px;

}

.lateral-padding-rigth {

    padding-right: 25px;
}

.btm-pding-5 {
    padding-bottom: 5px;
}

.lateral-margin {
    margin-left: 25px;
    margin-right: 25px;

}

.lateral-margin-left {
    margin-left: 25px;
}


.lateral-margin-right {
    margin-right: 25px;
}

.sp-btwn {
    justify-content: space-between !important;
}

.index-z-1 {
    z-index: 1 !important;
}

.index-z-2 {
    z-index: 2 !important;
}

.index-z-3 {
    z-index: 3 !important;
}



/* Margins */

.mg-btm-20 {
    margin-bottom: 20px;
}

.font-bold {
    font-weight: 600;
    padding-top: 5px;
}

.font-bold span {
    font-weight: 400;
}

.nowrap {
    flex-wrap: nowrap !important;
}



@media screen and (max-width: 760px) {

    .reverse-row-on-mobile {
        flex-direction: column-reverse;
    }

    .mg-btm-30-cento {
        margin-bottom: 30% !important;
    }

    .mobile-disable {
        display: none;
    }

}