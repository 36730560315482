@import url('https://fonts.googleapis.com/css?family=Poppins:400,700|Rubik:500,700&display=swap');

.section-pricing {
    font-size: 18px;
    position: relative;
    font-family: "Poppins", sans-serif;
    background-color: #ffedd9;
    padding: 30px 0;
    color: #4a6985;
}

.plans {

    overflow-x: auto;
    /* Enable horizontal scroll */
}

.section-pricing .header-section .plans-buttons {
    display: inline-block;
    margin-bottom: 35px;
    width: 100%;
    padding: 10px;
    font-family: "Rubik", sans-serif;
}

.section-pricing .header-section .plans-buttons .monthly,
.section-pricing .header-section .plans-buttons .yearly {
    display: inline-block;
    padding: 15px 40px;
    border: 1px solid rgba(0, 0, 0, 0);
    cursor: pointer;
    transition: all .3s;
}

.section-pricing .header-section .plans-buttons span:not(:last-child) {
    margin-right: 5px;
}

.section-pricing .header-section .plans-buttons .monthly:hover,
.section-pricing .header-section .plans-buttons .yearly:hover {
    border: 1px solid #ff9686;
}

.section-pricing .header-section .plans-buttons .active {
    background-color: #ff7c00;
    border: 1px solid #ff9686;
    color: #fff;
}

.section-pricing .header-section .plans-buttons .perc {
    display: inline-block;
    background-color: #9147e1;
    border-radius: 20px;
    color: #fff;
    font-size: 12px;
    padding: 1px 12px;
    margin-left: 10px;
}

.section-pricing .header-section .title {
    font-family: "Rubik", sans-serif;
    color: #000;
    font-size: 70px;
    font-weight: 700;
}

.section-pricing .header-section .description {
    font-size: 17px;
    margin-top: 15px;
}

.section-pricing .plans .single-plan {
    padding: 30px 30px;
    background-color: #fff;
    border-bottom: 6px solid #9147e1;
}

.section-pricing .plans .single-plan .title {
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 14px;
    font-family: "Rubik", sans-serif;
    letter-spacing: 0.1em;
    color: #9147e1;
}

.section-pricing .plans .single-plan .description {
    margin-bottom: 10px;
    font-size: 15px;
}

.section-pricing .single-plan .icon {
    display: inline-block;
    margin-bottom: 15px;
    border: 2px solid rgba(255, 107, 143, 0.1);
    border-radius: 50%;
    padding: 5px 10px 10px 10px;
}

.section-pricing .plans .single-plan .icon img {
    width: 110px;
}

.section-pricing .plans .single-plan .price {
    margin-bottom: 35px;
}

.section-pricing .plans .single-plan .price .dolar {
    color: #000;
    font-family: "Rubik", sans-serif;
}

.section-pricing .plans .single-plan .price .number {
    color: #000;
    font-family: "Rubik", sans-serif;
    font-size: 60px;
}

.section-pricing .plans .single-plan .price .plan-name {
    display: block;
    margin-top: -20px;
    font-size: 0.8em;
}

.section-pricing .plans .single-plan a.purchase-button {
    display: inline-block;
    background-color: #9147e1;
    color: #fff;
    font-family: "Rubik", sans-serif;
    padding: 15px 40px;
    text-decoration: none;
    transition: all .3s;
    font-size: 0.8em;
}

.section-pricing .plans .single-plan .purchase-button:hover {
    box-shadow: 0 5px 10px 0px rgba(255, 107, 143, .4);
}

.section-pricing .plans .active {
    position: relative;
    padding: 50px 30px;
    background-image: url(../../assets/background.jpeg);
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;
    border: none;
}

.section-pricing .plans .active:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(to right bottom, #ff7300, #ffa886);
    opacity: 0.9;
    z-index: 10;
}

.section-pricing .plans .active .content {
    position: inherit;
    z-index: 20;
}

.section-pricing .plans .active .title {
    color: #fff;
}

.section-pricing .active .icon {
    border-color: #fff;
    background-color: #fff;
}

.section-pricing .plans .active .price .dolar {
    color: #fff;
}

.section-pricing .plans .active .price .number {
    color: #fff;
}

.section-pricing .plans .active a.purchase-button {
    background-color: #02ff16;
    color: #000;
}

.section-pricing .plans .active .purchase-button:hover {
    box-shadow: 0 5px 10px 0px rgba(255, 255, 255, .4);
}

.know-more-button {
    font-size: 0.9rem;
    font-weight: bold;
    color: #007bff;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.know-more-button:hover {
    background-color: #0056b3;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
}

.know-more-button:active {
    background-color: #004085;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.know-more-button:focus {
    outline: none;
    box-shadow: 0 0 5px #0056b3;
}

.hero-plan-destaq {
    color: #48ff00;
}

.hero-plan-destaq:hover {
    background-color: #48ff00;
    color: #fff;
}

@media (max-width: 740px) {

    .know-more-button {
        font-size: 0.6rem;
    }

    .description {
        text-align: center;
    }

    
    .section-pricing .header-section .description {
        font-size: 10px;
    }

    .section-pricing .header-section {
        margin-top: -25px;
        margin-bottom: 25px;
    }

    .section-pricing .header-section .plans-buttons {
        margin-bottom: 15px
    }

    .section-pricing .plans .active {
        padding: 60px 30px;
    }

    .section-pricing {
        font-size: 12px;
    }

    .section-pricing .header-section .title {
        font-size: 30px;
    }

    .section-pricing .plans {}

    .section-pricing .plans .single-plan {
        padding: 15px 15px;
    }

    .section-pricing .plans .single-plan .description {
        margin-bottom: 10px;
        font-size: 12px;
    }

    .section-pricing .single-plan .icon {
        margin-bottom: 0px;
    }

    .section-pricing .plans .single-plan .icon img {
        width: 86px;
    }

    .section-pricing .plans .single-plan .price {
        margin-bottom: 12px
    }

    .section-pricing .plans .single-plan .price .plan-name {
        margin-top: 0px;
    }

    .section-pricing .plans .single-plan .price .number {

        font-size: 30px;
    }

    .section-pricing .plans .single-plan a.purchase-button {

        padding: 12px 28px;
    }

}

@media (min-width: 576px) and (max-width: 767.99px) {
    .section-pricing .header-section {
        margin-bottom: 60px;
    }

    .section-pricing .header-section .title {
        font-size: 60px;
    }
}

@media (max-width: 320px) {

    .plans-buttons .monthly,
    .plans-buttons .yearly {
        padding: 15px 20px !important;
    }
}

@media (min-width: 768px) and (max-width: 991.99px) {
    .section-pricing .header-section {
        margin-bottom: 60px;
    }
}