@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

header {
  max-width: 100vw;
  /* position: fixed !important; */
  z-index: 999;
  /*top: 0;*/
  left: 0;
  right: 0;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  background: transparent;
  transition: all ease 0.5s;
  background-color: rgb(0, 0, 0);
  font-size: 16px;
}

.header--rigthbox {

  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  transition: all ease 0.5s;
  color: white;
  font-size: 16px;
}

header.black {
  background: linear-gradient(var(--bg-color-black) 55%, transparent 100%);
}

.header--logo {
  height: 45px;
  cursor: pointer;

}

.header--logo img {
  height: 100%;
}

.header--user {
  margin: 0 0 0 25px;
  height: 35px;

}

.header--user img {
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: -2px 0px 18px 6px rgb(0 0 0 / 55%);
}

.accountList {
  display: block;
  overflow: hidden;
  position: absolute;
  font-size: 15px;
  font-weight: 400;
  /* top: 95%; */
  padding-top: 15px;
  padding-bottom: 15px;  
  width: 200px;
  z-index: 991;
  min-height: auto;
  right: 30px;
  max-height: calc(-83px + 100vh);
  max-width: calc(-24px + 100vw);
  color: white;
  background: #383b42;
  box-shadow: -7px 6px 11px 1px rgb(0 0 0 / 76%);
  border-radius: 5px;
}

.accountList li  {
  list-style: none;
}

.accountList li a {
  text-decoration: none
}

.accountList li span {
  color: white;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  user-select: none;

}

.accountList li span:hover {
  color: rgb(233, 90, 7);

}

.accountList li a:visited {
  color: rgb(255, 255, 255);
  text-decoration: none;

}

.accountList li a:hover {
  color: rgb(233, 90, 7);
  text-decoration: none;

}



@media (max-width:760px) {
  .header--logo span img {
    padding-top: 5%;
    height: 88%;
  }
}