/*LOADER*/
.book_preload {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #f28a4b;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;

}

.book_preload {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #ff3115;
    z-index: 999999;
}

.book {
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: relative;
    margin: 0 auto;
    border: 5px solid #ecf0f1;
    width: 100px;
    height: 60px;
}

.book__page {
    position: absolute;
    left: 50%;
    top: -5px;
    margin: 0 auto;
    border-top: 5px solid #ecf0f1;
    border-bottom: 5px solid #ecf0f1;
    border-right: 5px solid #ecf0f1;
    background: #e41f05;
    width: 50px;
    height: 60px;
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-animation: flip 1.2s infinite linear;
    animation: flip 1.2s infinite linear;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.book__page:nth-child(1) {
    z-index: -1;
    -webkit-animation-delay: 1.4s;
    animation-delay: 1.4s;
}

.book__page:nth-child(2) {
    z-index: -2;
    -webkit-animation-delay: 2.8s;
    animation-delay: 2.8s;
}

.book__page:nth-child(3) {
    z-index: -3;
    -webkit-animation-delay: 4.2s;
    animation-delay: 4.2s;
}

@-webkit-keyframes flip {
    0% {
        -webkit-transform: perspective(600px) rotateY(0deg);
        transform: perspective(600px) rotateY(0deg);
    }

    20% {
        background: #ff3115;
    }

    29.9% {
        background: #ff3115;
    }

    30% {
        -webkit-transform: perspective(200px) rotateY(-90deg);
        transform: perspective(200px) rotateY(-90deg);
        background: #e41f05;
    }

    54.999% {
        opacity: 1;
    }

    55% {
        opacity: 0;
    }

    60% {
        -webkit-transform: perspective(200px) rotateY(-180deg);
        transform: perspective(200px) rotateY(-180deg);
        background: #e41f05;
    }

    100% {
        -webkit-transform: perspective(200px) rotateY(-180deg);
        transform: perspective(200px) rotateY(-180deg);
        background: #e41f05;
    }
}

@keyframes flip {
    0% {
        -webkit-transform: perspective(600px) rotateY(0deg);
        transform: perspective(600px) rotateY(0deg);
    }

    20% {
        background: #ff3115;
    }

    29.9% {
        background: #ff3115;
    }

    30% {
        -webkit-transform: perspective(200px) rotateY(-90deg);
        transform: perspective(200px) rotateY(-90deg);
        background: #e41f05;
    }

    54.999% {
        opacity: 1;
    }

    55% {
        opacity: 0;
    }

    60% {
        -webkit-transform: perspective(200px) rotateY(-180deg);
        transform: perspective(200px) rotateY(-180deg);
        background: #e41f05;
    }

    100% {
        -webkit-transform: perspective(200px) rotateY(-180deg);
        transform: perspective(200px) rotateY(-180deg);
        background: #e41f05;
    }
}