.btn--aventura--comprar {
  background-color: #f15208;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  font-weight: 600;
}

.product-bottom::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;

  width: calc(100% - 28px);
  height: 2px;
  background: #fd6703;
  background: linear-gradient(to right, #e7cbb8 0%, #ec6307 20%, #fd6703 20%, #fd6703 100%);
}

.modal--content {
  margin-top: 20rem;
}

.pagarme--powered {
  margin-top: 5px;
  width: 225px;

}

.pagarme--powered-b {
  margin-top: 5px;
  width: 430px;

}

.product--modal--contents {
  margin-bottom: 10px;
}

.product--modal--title {

  color: #fff;
  z-index: 2;
  font-size: 28px;
  font-weight: 800;

}

.modal--price {
  color: #27c055;
  font-size: 1.3em;
  font-weight: 600;
  margin-top: 10px;
  margin-left: 0px;
  margin-bottom: 10px;
  text-align: right;
  margin-right: 15px;
}

.modal--sinopse {
  color: #fff;
  font-size: 21px;
  font-weight: 600;
  padding-bottom: 15px;
}

.btn-custom-white {
  background-color: #fff;
}

.btn-custom-translucent {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.btn-custom-translucent:hover {
  color: #fff;
  opacity: 0.8;
}

.btn-custom-white:hover {
  opacity: 0.8;
}

.btn-custom-round {
  padding: 5px 9px !important;
}

.opacity-50 {
  opacity: 0.5;
}

/* MODAL */
.modal-dialog,
.modal-content {
  background: #171717;
  margin-top: 5%;
}

.modal-dialog {
  max-width: 600px;
}

.modal-hero {
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.modal-hero::after {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  display: block;
  background: linear-gradient(180deg, rgba(23, 23, 23, 0) 0%, #171717 50%);
  position: absolute;
  left: 0;
  z-index: 1;
}

.modal-hero-pos {
  height: 100%;
  padding: 40px 40px 100px 40px;
}


.title-lettering {
  width: 250px;
  max-height: 180px;
  margin-top: 40px;
  position: relative;
  z-index: 2;
  margin-bottom: 40px;
}

.modal-hero-infos {
  display: flex;
  position: relative;
  z-index: 2;
  justify-content: space-evenly;
  align-items: center;
}

.modal-hero-infos-plus {
  display: flex;
  position: relative;
  z-index: 2;
  justify-content: flex-start;
  align-items: center;
}

.modal-infos {
  z-index: 3;
}

.modal-hero-infos button:first-child {
  margin-right: 20px;
}

.modal-hero-infos-icons {
  max-width: 30px !important;
  max-height: 30px !important;
  margin: 0;
  margin-right: 10px;
}

/* Para estilos específicos para dispositivos móveis, você pode usar uma media query */

@media (max-width: 768px) {
  .modal-hero-infos-icons {
    max-width: 20px !important; /* Ajuste conforme necessário para dispositivos móveis */
    max-height: 20px !important; /* Ajuste conforme necessário para dispositivos móveis */
    margin-right: 5px; /* Ajuste conforme necessário para dispositivos móveis */
  }
}

.filme_descricao {
  font-size: 15px;
  padding: 10px 10px 10px 0px;
  color: #fff;
  max-height: 20vh;
  overflow-y: auto;
  margin-bottom: 15px;
}

.filme_elenco {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.55);
}

#lista_episodeos {
  list-style: none;
  padding: 0;
}

#lista_episodeos li:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.05);
}

#lista_episodeos li {
  padding: 20px;
}

#lista_episodeos li p {
  font-size: 13px;
}


/* AGREGATE */

#modal--open-pdf {
  padding-left: -4px !important;
}

.modal-content-pdf {
  text-align: center;
  background: rgb(50, 54, 57) !important;
  margin-top: -65px;
  height: 100vh;
  width: 100vw;

}

.modal-header-pdf {
  text-align: right;
  padding-top: 10px;
  padding-right: 15px;
}

.modal-dialog-pdf {
  max-width: 100% !important;
  margin-left: 0 !important;
}

#pdf--iframe {
  border: none;
  width: 100%;
  height: 100%;
  background: rgb(50, 54, 57);
}

@media (max-width:760px) {
  .modal-hero {
    height: 350px;
  }

  .title-lettering {
    width: 187px;
  }

  .modal--price {
    font-size: 0.8em;
  }

  .modal-hero-pos {
    padding: 40px 10px 100px 10px;
  }
}