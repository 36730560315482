@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans&display=swap');

/*Setas*/

.productRow--left,
.productRow--right {
  font-size: 50px;
  position: absolute;
  width: 40px;
  height: 355px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
  transition: all ease 0.2s;

}

.productRow--left {
  left: 0;
}

.productRow--right {
  right: 0;
}

.productRow:hover .productRow--left,
.productRow:hover .productRow--right {
  opacity: 1;
}

.mini {
  height: 255px;
}

@media (max-width:760px) {

  .productRow--hero {
    margin-top: -5vh;
  }

  .productRow--right,
  .productRow--left {
    height: 230px;
  }

  .productRow h2 strong,
  .productRow h2 span {
    font-size: 14px !important;
  }

  .productRow--item {
    margin-right: 10px !important;
    width: 160px !important;
    height: 230px !important;
  }

  figcaption {
    display: none !important;
  }

  .productRow {
    margin-bottom: 20px;
  }
}

/*Lista*/

.productRow--hero {
  width: 100%;
  height: 100%;
  background-color: var(--bg-color-black);
  color: #fff;
  margin: 0;
  font-family: 'Roboto', sans-serif;  
}

.productRow {
  margin-bottom: 55px;
}

.productRow--list {
  transition: all ease 0.5s !important;

}

.productRow--listarea {
  padding-left: 15px;
  overflow-x: scroll;
}

.productRow--listarea::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}

.productRow--item {
  display: inline-block;
}

.session-text {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  margin: 0px 0 0px 0;
  padding-left: 15px;
  margin-bottom: 25px;
  color: #b9b8b8;
}



.session-text h1 {
  font-size: 1.5em;
  margin-bottom: 5px;
  color: #777474;
}

.session-text span {
  color: var(--bg-color-orange-strong);
  text-decoration: none;
  font-size: 1em;
  display: none;
}

.session-text a {
  color: var(--bg-color-gray-strong);
  text-decoration: none;
  font-size: 1em;
}


.productRow--list,
.news {
  flex-flow: row wrap;
}

.productRow--item img,
.productRow--item img {
  width: 100%;
  min-height: 100%;
}

.productRow--item {
  background-color: rgba(255, 255, 255, 0) !important;
}

.productRow--item {
  margin-right: 10px;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 235px;
  height: 350px;
  overflow: hidden;
  box-shadow: 0 5px 10px rgb(0 0 0 / 80%);
  transform-origin: center top;
  transform-style: preserve-3d;
  transform: translateZ(0);
  transition: 0.3s;
}

.productRow--item:hover,
.news .productRow--item:hover,
.productRow--item:focus,
.news .productRow--item:focus,
.productRow--item:active,
.news .productRow--item:active {
  box-shadow: 0 8px 16px 3px rgb(0 0 0 / 60%);
  transform: translateY(-3px) scale(1.05) rotateX(15deg);
}

.productRow--item:hover:after,
.news .productRow--item:hover:after,
.productRow--item:focus:after,
.news .productRow--item:focus:after,
.productRow--item:active:after,
.news .productRow--item:active:after {
  transform: rotate(25deg);
  top: -40%;
  opacity: 0.15;
}

.productRow--item:hover figcaption,
.news .productRow--item:hover figcaption,
.productRow--item:focus figcaption,
.news .productRow--item:focus figcaption,
.productRow--item:active figcaption,
.news .productRow--item:active figcaption {
  transform: none;
}

.productRow--item figcaption {
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  padding-bottom: 10px;
  font-size: 20px;
  background: none;
  color: #fff;
  transform: translateY(100%);
  transition: 0.3s;
}

.productRow--item:hover figcaption {
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  padding-bottom: 10px;
  font-size: 20px;
  background: none;
  color: #fff;

  transition: 0.3s;
}

.rel,
h2,
h2 strong,
.productRow--item,
.news .productRow--item,
.news .article {
  position: relative;
}

figure {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}

.productRow--item:after,
.news .productRow--item:after {
  content: '';
  z-index: 10;
  width: 200%;
  height: 100%;
  top: -90%;
  left: -20px;
  opacity: 0.1;
  transform: rotate(45deg);
  background: linear-gradient(to top, transparent, #fff 15%, rgba(255, 255, 255, 0.5));
  transition: 0.3s;
  position: absolute;
}

@media (max-width:740px) {

  .session-text {
    margin: 25px 0 25px 0;
  }

  .session-text h1 {
    font-size: 1.15em;
  }

  .session-text-span {
    font-size: 0.8em;
    max-width: 70%;
  }

  .session-text span {    
    font-size: 0.75em;
  }
}
