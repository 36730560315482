/* helper */

.container-login-page {
  display: flex;
  align-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 62.5%;
  line-height: 1.5;
  background-color: var(--bg-color-orange);
  height: 100vh;
}

.frm-main-container {
  position: relative;
  max-width: 450px;
  min-width: 320px;
  margin: 0 auto;
  background-image: url("https://media-exp1.licdn.com/dms/image/C4D1BAQGcf3IWftahLA/company-background_10000/0/1570403698813?e=2147483647&v=beta&t=4OASl70eXPVFEBv8kNVKJjs-I-FZdDSZx2zolMQ_IBQ");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  box-shadow: 0px 0px 50px rgb(0 0 0 / 50%);
  z-index: 1;
  padding: 30px;
  height: auto;

}

.frm-main-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to top left, rgba(0, 0, 255, 0.53), rgba(255, 105, 180, 0.67));
  z-index: 2;
}

.frm-main-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3;
}

.frm-main-container .inner {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 5em 6em;
  z-index: 9;

}

@media (max-width: 760px) {

  .frm-main-container {
    max-width: 330px !important;
  }

  .inner {
    padding: 1em 1.2em !important;
  }

  .frm-main-container .frm-change-links span {
    font-size: 1.2em !important;
  }

}


.frm-main-container .frm-change-links span {
  color: #808080;
  font-size: 1.5em;
  font-weight: 500;
  font-style: normal;
  text-decoration: none;
  padding: 0.6em 0;
  letter-spacing: 0.1em;
  border-bottom: 1px solid #808080;
  display: inline-block;
  cursor: pointer
}

.frm-main-container .frm-change-links span:first-child {
  margin-right: 5em;
}

.frm-main-container .frm-change-links span.active {
  color: var(--font-color-white);
  border-bottom: 1px solid rgb(235, 93, 11);
}

.frm-main-container .frm-group {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--font-color-white);
  margin: 0 0 1.5em;
  transition: all 1s ease;
}

.frm-main-container .frm-group.chk {
  flex-direction: row;
  padding: 0 2em;
}

.frm-main-container .frm-group.chk label {
  cursor: pointer;
  padding: 0 0 0 1em;
}

.frm-main-container input[type=text],
.frm-main-container input[type=email],
.frm-main-container input[type=password],
.frm-main-container button[type=submit],
.frm-main-container select {
  font-family: inherit;
  font-weight: inherit;
  font-size: 1.6em;
  border: 0;
  background: rgba(255, 255, 255, 0.1);
  padding: 0.9em 2em;
  border-radius: 1.8em;
  color: var(--font-color-white);
  outline: none;
  letter-spacing: 0.15em;
  width: 100%;
}

.frm-main-container input[type=password] {
  letter-spacing: 0.2em;
}

.frm-main-container button[type=submit] {
  font-size: 1.4em;
  font-weight: 400;
  background: var(--bg-color-btn-green);
  text-transform: uppercase;
  cursor: pointer;
}

.frm-main-container button[type=submit]:hover {
  background: #fa5e03;
}

.cad_fones {
  display: inline-flex;
  flex-flow: row;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;

}

#cad_name {
  margin-right: 15px !important;
}

#cad_ddifone {
  margin-right: 15px;
  width: 25%;
}

#cad_fone {
  width: 75%;

}

.loadingBtn:after {
  content: "";
  display: inline-block;
  margin-left: 5px;
  width: 10px;
  height: 10px;
  margin-right: 20px;
  border-radius: 50%;
  border: 3px solid white;
  border-left-color: transparent;
  border-top-color: transparent;
  animation: loading 1s linear infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.frm-main-container select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.frm-main-container select option {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: #333;
}

.frm-main-container label {
  font-size: 1.2em;
  letter-spacing: 0.1em;
  padding: 0 0 0 2em;
  margin: 0 0 1em;
  text-transform: uppercase;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.frm-main-container hr {
  display: block;
  height: 2px;
  background: rgba(255, 255, 255, 0.15);
  border: none;
  margin: 4em 0;
}

.frm-signin {
  position: relative;
  margin: 4em -2em 0;
  display: block;
}

.frm-signin .fp-link {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  font-size: 1.4em;
  font-weight: inherit;
  letter-spacing: 0.1em;
  color: var(--font-color-white);
  /*text-transform: capitalize;*/
  border-bottom: 1px solid transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.frm-signin .fp-link:hover {
  color: var(--bg-color-orange);
}

.frm-signup {
  position: relative;
  margin: 4em -2em 0;
  /* display: none; */
}

.form-control:focus {
  box-shadow: 0 0 0 0.2em rgba(245, 92, 22, 0.71) !important;
}

::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.65);
}

::-moz-placeholder {
  color: rgba(255, 255, 255, 0.65);
}


/* Botão Senha */

.btn-show-pass {
  background: none;
  border: none;
  color: var(--font-color-white);
  font-size: 2.5em;
  font-weight: 600;
  position: absolute;
  right: .75em;
  bottom: 0%;
  z-index: 9;
}
