.header--cart {
  margin: 0 0 0 25px;
  cursor: pointer;
}

/* cart */

.fa-shopping-cart {
  padding-right: 10px;
  font-size: 18px;
}

.header--cartList {
  display: none;
  overflow: hidden;
  position: absolute;
  font-size: 15px;
  font-weight: 400;
  padding: 30px;
  width: 100%;
  z-index: 99;
  margin: 15px auto;
  min-height: auto;
  right: 88px;
  max-height: calc(-83px + 100vh);
  max-width: calc(10px + 32vw);
  background: #383b42;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  box-shadow: -7px 6px 11px 1px rgb(0 0 0 / 76%);
  min-width: 350px;
}

.activeCartList {
  display: block;
}

.header--cartCount {
  display: inline-block;
  /*padding-top: 3px;
      padding-bottom: 3px;*/
  padding-right: 6px;
  padding-left: 6px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  font-size: 11px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
  height: auto;
  background-color: #f1410b;
  color: #fff;
}


#cartEmpty {
  align-content: center;
}

.shopping-cart-items {
  max-height: 50vh;
  overflow-y: auto;
  padding: 15px;
  background-color: #505257;
}

.items-flex-intern {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}

.product-off {
  position: absolute;
  right: 10px;
  color: #ee4646;
  font-size: 1.2em;
}

.items-flex-bottom {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.shopping-cart-items {
  margin-bottom: 18px;
}

.shopping-cart-items img {
  float: left;
  margin: 12px 12px 12px 0;
  height: 80px;
  width: 50px;
}

.shopping-cart-items .item-name {
  display: block;
  padding-top: 10px;
  font-size: 16px;
}

.shopping-cart-items .item-price {
  color: #6394F8;
  margin-right: 8px;
}

.shopping-cart-items .item-quantity {
  color: #ABB0BE;
}

.shopping-cart-items .item-out {
  float: right;
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;

}

.header--cartList .btn-primary {
color: var(--bg-color-black);

}

.header--cartList .btn-primary:hover {
  background-color: #0db31b;
  color: #383b42;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width:760px) {
  .header--cartList {
    font-size: 14px !important;
    font-weight: 400;
    padding: 35px;
    width: 90%;
    z-index: 99;
    margin: 15px auto;
    min-height: auto;
    right: 5px;
    min-width: 97%;
  }
  .header--cartList span{
    font-size: 14px !important;
    max-width: 80%;    
  }
}