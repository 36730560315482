.featured {
    height: 75vh;
    background-size: cover;
    background-position: center;
    color: #fff;
}

.featured--vertical {
    width: inherit;
    height: inherit;
    background: linear-gradient(to top, var(--bg-color-black) 20%, transparent 90%);
}

.featured--horizontal {
    width: inherit;
    height: inherit;
    background: linear-gradient(to right, var(--bg-color-black) 30%, transparent 70%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
}

.featured--name {
    font-size: 60px;
    font-weight: bold;
}

.featured--lettering {
    margin-top: 5%;
    max-width: 30%;
}

.featured--description {
    margin-top: 1em;
    font-size: 1em;
    max-width: 60%;
    color: rgb(221, 221, 221);
}

.featured--buttons {
    margin-top: 1.5em;
}

.featured--watchbutton,
.featured--mylistbutton {
    display: inline-block;
    font-size: 1em;
    font-weight: bold;
    padding: 10px 25px;
    border-radius: 5px;
    text-decoration: none;
    margin-right: 10px;
    opacity: 1;
    cursor: pointer;
}

.featured--watchbutton:hover,
.featured--mylistbutton:hover {
    opacity: 0.7;
}

.featured--watchbutton {
    background-color: #fff;
    color: black;
    box-shadow: -2px 3px 5px 0px rgba(36, 37, 36, 0.9);
}

.featured--mylistbutton {
    background-color: rgba(22, 21, 21, 0.5);
    color: rgb(250, 250, 250);
}


/* Carrocel */

.carousel {
    margin-bottom: 1%;
}

.carousel-indicators {
    position: absolute;
    bottom: 0;
}

.carousel-indicators button {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    margin-right: 15px;
    background-color: #160b0133;
    border-color: #fff;
}

.carousel-indicators button.active {
    background-color: #fff;
}

@media (max-width:760px) {
    .featured {
        height: 80vh;
    }

    .featured--name {
        font-size: 40px;
    }

    .featured--lettering {
        max-width: 320px;        
    }

    .featured--info {
        font-size: 16px;
    }

    .featured--description {
        font-size: 13px;
        max-width: 100%;
        margin-right: 30px;
        padding-left: 30px;
    }

    .featured--watchbutton,
    .featured--mylistbutton {
        font-size: 0.7em;
        padding: 10px 20px;
    }

    .featured--genres {
        font-size: 14px;
    }

    .featured--vertical {
        height: 85vh;
    }

    .featured--horizontal {
        max-width: 100vw;
        padding-left: 0;
        align-items: center;
    }

    .guild-hero-image {
        width: 75vw !important;
        margin-top: -60px;
    }

    .carousel-indicators {
        position: absolute;
        bottom: -20px;
    }
}


/* GUILD PROMO */

.guild-hero-image {
    max-width: 320px !important;
    margin-left: -15px;
}

/* Slide 2 */

.button-guild {
    fill: #030303;
    color: #030303;
    background-color: #08FF2E;
    box-shadow: -2px 3px 5px 0px rgb(15 135 39 / 90%);
    cursor: pointer;
}