
.featured-blog {
    height: 80vh;
    background-size: cover;
    background-position: center;
    color: #fff;
    margin-bottom: -5%;
}

.featured-vertical-blog {
    width: inherit;
    height: inherit;
    background: linear-gradient(to top, var(--bg-color-black) 20%, transparent 90%);
}


.hero-blog-title-div {
    position: relative;
    align-items: center;
    top: 50%;
    padding: 0 30px 0 30px;
}

.hero-blog-title {
    text-align: center;
    color: #fff;
    font-size: 40px;
    font-weight: 800;
    text-transform: uppercase;
}

.card-bodyz {    
    font-size: 16px;
    font-weight: 400;
    color: #eaf2f7;
    font-family: Roboto, sans-serif, serif;    
}

.card-bodyz p {
    margin-bottom: 25px;
}

@media screen and (max-width: 600px) {
    .featured--blog {       
        height: 50vh;   
        margin-bottom: -20%;
    }
    .hero-blog-title-div { 
             
        top: 30%;
        padding: 0 30px 0 30px;
    }
    .hero-blog-title {        
        font-size: 30px;
    }
    .blog-text {
        margin-top: -25%;
    }
}