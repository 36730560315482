.bestiary-container {
    background-color: #0c101b;
    border-radius: 5px;
    min-height: 520px;
    padding: 15px;
    margin-bottom: 50px;
}

.playlist {
    user-select: none;
    font-size: 12px;
    cursor: pointer;

}

.monster-select {
    transition: all 0.2s ease;
    margin-bottom: 10px;
}

.monster-select:hover {
    transform: scale(1.01);
    color: #ff1744;
    transition: all 0.2s ease;
}

.btn-ficha-completa {
    font-size: 12px;
    padding: 8px;
}

.icon {
    font-size: 24px;
}

.playlist h6 {
    font-size: 14px;
}

.flex-v-center {
    display: flex;
    align-items: center;
}

.r-3 {
    border-radius: 3px !important;
    max-width: 36px;
    max-height: 36px;
}

.btn-outline-primary {
    background-color: transparent;
    border: 1px solid transparent;
    color: #ffffff;
    border-color: #ff5605;
}

.btn-outline-primary:hover {
    background-color: #f17a42;
    border: 1px solid transparent;
    color: #ffffff;
    border-color: #ff5605;
}

.btn-monstro-guilda {
    font-size: 12px;
    padding: 8px;
    background-color: transparent;
    border: 1px solid transparent;
    color: #ff7417;
    border-color: #f86807;
}

.no-link a {
    text-decoration: none;
    color: inherit;
}

.search-input-bestiary {
    color: #ffffff;
}

/*FILTER*/

#myBtnContainer {
    position: relative;
    padding-bottom: 15px;
}

#myInput {
    background-color: rgba(0, 0, 0, 0.096);
    width: 30%;
    padding: 10px 5px 10px 15px;
    border: 1px solid rgba(126, 125, 125, 0.308);
    position: absolute;
    right: 5px;
}

@media (max-width: 740px) {

    .bestiary-container {        
        margin-right: 15px;
    }

    #myInput {        
        width: 45%;
        font-size: 14px;
        padding: 5px 6px 6px 15px;
        border: 1px solid rgba(126, 125, 125, 0.308);
        position: absolute;
        right: 5px;
    }

    .playlist h6 {
        margin-bottom: 0px;
    }
    .monster-select {
        margin-bottom: 15px;
    }
   
}