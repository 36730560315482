/* Estilo padrão para a imagem */
.promo-monster-thumb {
    width: 100%; /* Define a largura máxima para a imagem */
    max-width: 250px; /* Define um tamanho máximo para a imagem */
    border-radius: 25px;
    height: auto; /* Mantém a proporção da imagem */
  }
  
  /* Estilo para dispositivos móveis (tamanhos menores de tela) */
  @media (max-width: 768px) {
    .promo-monster-thumb {
      max-width: 150px; /* Define um tamanho máximo menor para dispositivos móveis */
    }
  }