.spotfy-embed {
    
    
}

.spotfy-embed  {
    padding-right: 25px;
    margin-bottom: 20px;    
}


/*FILTER*/

.container {
    overflow: hidden;
    max-width: 100%;
    padding: 15px;
}

.filterDiv {
    float: left;
    background-color: #2196F3;
    color: #ffffff;
    width: 19%;
    height: 200px;
    line-height: 100px;
    text-align: center;
    margin: 3px;
    display: none;
    /* Hidden by default */
}

/* The "show" class is added to the filtered elements */
.show {
    display: block;
}

/* Style the buttons */
.btn {
    border: none;
    outline: none;
    
    cursor: pointer;
    color: rgba(204, 204, 204, 0.281);
}

/* Add a light grey background on mouse-over */
.btn:hover {
    color: #ddd;
}

/* Add a dark background to the active button */
.btn.active {

    color: white;
}

/*TABLE*/

#tableWrapper {
    position: relative;
}

#tableScroll {
    height: 500px;
    max-height: 350px;
    overflow-y: auto;
}

#myTable {
    border-collapse: collapse;
    width: 100%;
    font-size: 14px;

    height: 10%;
}

#myTable th,
#myTable td {
    text-align: left;
    padding: 12px;
}

#myTable tr {
    border-top: 1px solid rgba(0, 0, 0, 0.096);
}

#myTable tr:hover {
    background-color: #00000041;
}

/*BLOG LIST*/

@import url('https://fonts.googleapis.com/css?family=Fira+Sans|Lobster|Montserrat|Roboto');


.blog-card {
    
    padding: 20px 30px 20px 15px;
    border-radius: 10px;
    background-blend-mode: multiply;
    position: relative;
    margin-bottom: 20px;
    z-index: 1;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease;
    cursor: pointer;
    background-image: url('https://media-exp1.licdn.com/dms/image/C4D1BAQGcf3IWftahLA/company-background_10000/0/1570403698813?e=2147483647&v=beta&t=4OASl70eXPVFEBv8kNVKJjs-I-FZdDSZx2zolMQ_IBQ');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.b {
    background-image: url('https://www.wargamer.com/wp-content/sites/wargamer/2021/10/roll20-tutorial-map.jpg');
}

.blog-card:hover {
    transform: scale(1.01);
    background-size: 120% !important;
}

.wp-block-gallery {
    display: flex;
}

.wp-block-image img {
    max-width: 100%;
    max-height: fit-content;
}

.card-overlay {
    background: linear-gradient(to right, rgba(42, 159, 255, 0.2) 0%, #212120 45%, #212120 100%);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 10px;
}

.blog--info--card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-end;

}

.blog-card-description {
    width: 60%;
    color: #fff;
    font-size: 12px;
}

.blog-title {
    margin: 0;
    color: #fff;
}

.blog-subtitle {
    margin-top: 5px;

}

.blog-shorts {
    font-family: Roboto;
    text-align: justify;
}

.watch-btn {
    font-family: Fira Sans;
    position: absolute;
    margin: auto;
    background: transparent;
    font-size: 12px;
    cursor: pointer;
    color: rgb(255, 123, 0);
    transition: all 0.5s ease;
    right: 15px;
    bottom: 10px;
}

.watch-btn:hover {
    color: #f00;

}


@media screen and (max-width: 768px) {
    .blog-card {        
        box-sizing: border-box;
        padding: 20px;
    }

    .blog--info--card {
        flex-direction: row;
    }

    .blog--info--card-overlay {
        background: linear-gradient(to bottom, rgba(42, 159, 255, 0.2) 0%, #212120 60%, #212120 100%);
    }

    .spotfy-embed iframe {        
        margin-bottom: 0px;    
    }

}