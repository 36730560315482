@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

.py-5 .my-5 {
	background: #f9f9f9;
	font-family: "Roboto", sans-serif;
}

.btn {
	font-size: 15px;
}

.btn-font-white {
	color: #f9f9f9
}

.d-sm-flex {
	font-size: 16px;
	box-shadow: 0px 0px 50px rgb(0 0 0 / 50%);
}

.form-control {
	font-size: 12px !important;
}

.btnReturn a {
	cursor: pointer !important;
	color: black;
	font-size: 32px;
}

.shadow {
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1) !important;
}

.profile-tab-nav {
	min-width: 250px;
}

.tab-content {
	flex: 1;
}

.form-group {
	margin-bottom: 1.5rem;
}

.nav-pills a.nav-link {
	padding: 15px 20px;
	border-bottom: 1px solid #ddd;
	border-radius: 0;
	color: #333;
}

.nav-pills a.nav-link i {
	width: 20px;
}

.img-circle img {
	height: 100px;
	width: 100px;
	border-radius: 100%;
	border: 5px solid #fff;
}


@media screen and (max-width: 768px) {
	.nav-pills {
		flex-direction: row !important;
		flex-wrap: nowrap !important;
		overflow-x: scroll;
	}

}